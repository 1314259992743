import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { browserTracingIntegration, replayIntegration } from '@sentry/react'
import React from 'react'
import { VelprContext } from '@smorgrav/hundehall_shared'

const sentryInit = (context: VelprContext) => {
  Sentry.init({
    dsn: 'https://4e92202fc676903efac580386baa554e@o4508721502552064.ingest.de.sentry.io/4509072838099024',
    environment: import.meta.env.MODE || 'local',
    enabled: import.meta.env.MODE !== 'local',
    release: import.meta.env.APP_VERSION,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    // Performance monitoring
    tracesSampleRate: 0.5, // Capture 50% of transactions
    // Session replay for errors
    replaysSessionSampleRate: 0.1, // Sample 10% of sessions
    replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors
    beforeSend: (event) => {
      event.tags = event.tags || {}
      if (context.siteContext) {
        event.tags.site = context.siteContext.siteId
      }
      return event
    }
  })
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export { SentryRoutes, sentryInit }
