import React from 'react'
import { createRoot } from 'react-dom/client'
import { contextFromUrl } from '@smorgrav/hundehall_shared'
import './index.css'
import consoleWelcome from '@/utils/consoleWelcome'
import { sentryInit } from './instrument'

window.context = contextFromUrl(
  new URL(window.location.href),
  import.meta.env.MODE,
  'app'
)

console.log('context', window.context)
console.log('APP_VERSION', import.meta.env.APP_VERSION)

sentryInit(window.context)

// Make sure we dont init any react components before we have the context
const loadReactComponent = () => {
  const container = document.getElementById('root')
  const root = createRoot(container!)
  import('./App')
    .then(({ default: App }) => {
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      )
    })
    .catch((error) => {
      console.error('Failed to load App', error)
    })
}

consoleWelcome()

loadReactComponent()
